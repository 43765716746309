<template>
  <div>
    <v-dialog v-model="dialog" max-width="500">
      <v-card class="pa-5" flat>
        <v-toolbar class="d-flex justify-center font-weight-bold" flat>
          New Request
        </v-toolbar>
        <v-form lazy-validation ref="form" class="text-left">
          <label>Link Label <span class="error--text">*</span></label>
          <v-text-field
            solo
            dense
            rounded
            flat
            type="text"
            background-color="secondary"
            v-model="form.label"
            placeholder="Enter link label"
            :rules="validate.required('Link label')"
          ></v-text-field>
          <div v-if="record_link !== ''">
            <label>Here is the screen recording link</label>
            <v-text-field
              solo
              dense
              rounded
              flat
              background-color="secondary"
              v-model="record_link"
              readonly
              id="videoLink"
            >
              <template v-slot:append>
                <v-icon
                  @click="copyLink"
                  size="16"
                  class="white rounded-xl mr-n5 pa-2 pointer"
                >
                  mdi-content-copy
                </v-icon>
              </template>
            </v-text-field>
          </div>
        </v-form>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            @click="$refs.form.validate() ? createRequest() : null"
            class="capitalize primary px-8"
            rounded
            large
            :loading="loadRequest"
          >
            create request
          </v-btn>
          <v-btn
            class="capitalize secondary elevation-0 px-5 grey--text"
            rounded
            large
            @click="closeDialog"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" bottom right color="success darken-2">
      {{ success_msg }}
    </v-snackbar>
  </div>
</template>

<script>
import FormValidation from "../utils/FormValidation";
import ResponseHelper from "../utils/ResponseHelper";
import { mapActions } from "vuex";
export default {
  name: "NewRequest",
  data() {
    return {
      form: {
        request_type: "link",
      },
      validate: new FormValidation(),
      toast: new ResponseHelper(),
      categories: [],
      loading: false,
      loadRequest: false,
      dialog: false,
      record_link: "",
      success_msg: "",
      snackbar: false,
    };
  },
  methods: {
    ...mapActions({
      get_categories: "settings/get_categories",
      new_request: "recordings/new_request",
    }),
    async createRequest() {
      this.loadRequest = true;
      try {
        const response = await this.new_request(this.form);
        this.loadRequest = false;
        this.toast.sendSuccess(response);
        this.record_link = response.data.record_link;
        this.$root.$emit("reload-links");
      } catch (e) {
        this.loadRequest = false;
        this.toast.sendError(e);
      }
    },
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    copyLink() {
      let link = document.getElementById("videoLink");
      link.select();
      document.execCommand("copy");
      this.closeDialog();
      this.success_msg = "Link copied to clipboard";
      this.snackbar = true;
    },
  },
};
</script>

<style scoped></style>
