<template>
  <v-app class="layout-bg" app>
    <v-navigation-drawer
      :style="loading ? { zIndex: 1 } : {}"
      floating
      :width="230"
      v-model="drawer"
      app
    >
      <v-list-item>
        <v-list-item-content>
          <app-logo></app-logo>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-avatar size="60">
          <v-img :src="user['image'] ? user['image'] : avatar"></v-img>
        </v-avatar>
        <div style="position: absolute; bottom: 0; left: 55px">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                class="primary white--text rounded-circle pointer float-right"
                size="20"
              >
                mdi-chevron-down
              </v-icon>
            </template>
            <v-list>
              <v-list-item
                link
                :to="link.to"
                exact
                v-for="(link, index) in profileLinks"
                :key="index"
              >
                <v-list-item-icon class="text-12">
                  <v-icon>{{ link.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content class="text-12 ml-n4">
                  {{ link.title }}
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <small class="ml-2">{{ user.name }}</small>
      </v-list-item>
      <v-list dense class="mt-4">
        <v-list-item
          v-for="(link, i) in links"
          :key="i"
          :to="link.to"
          link
          exact
          active-class="active-link"
        >
          <v-list-item-icon class="active-text text-12">
            <v-icon>{{ link.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content
            :class="pageName === link.title ? 'text-12 active-text' : 'text-12'"
          >
            {{ link.title }}
          </v-list-item-content>
        </v-list-item>
        <v-list-item link @click="logout">
          <v-list-item-icon class="text-12">
            <v-icon>mdi-power</v-icon>
          </v-list-item-icon>
          <v-list-item-content class="text-12">Logout</v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar color="secondary" flat app>
      <!--      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>-->
      <v-toolbar-title class="text-h6 text-capitalize pl-2">
        {{ $route.name !== "UserDetails" ? pageName : user.fullname }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-text-field
        v-if="pagesWithSearch.includes(pageName)"
        rounded
        v-model="searchRecordings"
        dense
        class="white"
        style="max-width: 300px; height: 38px"
        placeholder="Search with category or name"
        @input="searchRequest"
      >
        <template v-slot:prepend-inner>
          <v-icon class="secondary rounded-xl mt-1 pa-1 ml-n5"
            >mdi-magnify</v-icon
          >
        </template>
      </v-text-field>
      <div class="mr-2 ml-2">
        <v-btn
          to="/record-screen"
          text
          class="primary rounded-xl white--text capitalize"
          target="_blank"
        >
          <v-icon class="white primary--text rounded-xl pa-1 mr-1 ml-n3" small>
            mdi-share-outline
          </v-icon>
          record
        </v-btn>
        <v-btn
          @click="$refs.new_request.openDialog()"
          text
          class="primary rounded-xl white--text capitalize ml-2"
        >
          <v-icon class="white primary--text rounded-xl pa-1 mr-1 ml-n3" small>
            mdi-plus
          </v-icon>
          new request
        </v-btn>
        <new-request ref="new_request"></new-request>
      </div>
    </v-app-bar>
    <v-main>
      <loading-progress></loading-progress>
      <v-container class="mt-4">
        <router-view />
      </v-container>
    </v-main>
    <v-footer class="white">
      <v-card-text>
        &copy; {{ app_name + " " + new Date().getFullYear() }}
      </v-card-text>
    </v-footer>
  </v-app>
</template>

<script>
import AppLogo from "../components/Logo";
import NewRequest from "../components/NewRequest";
import { mapMutations, mapGetters } from "vuex";
export default {
  components: {
    AppLogo,
    NewRequest,
  },
  name: "protected",
  data() {
    return {
      avatar: require("../assets/avatar.png"),
      app_name: process.env.VUE_APP_NAME,
      links: [
        {
          to: "/dashboard",
          title: "Dashboard",
          icon: "mdi-view-dashboard-outline",
        },
        {
          to: "/my-recordings",
          title: "My Recordings",
          icon: "mdi-play-circle-outline",
        },
        {
          to: "/received-recordings",
          title: "Received Recordings",
          icon: "mdi-briefcase-check-outline",
        },
        {
          to: "/requested-links",
          title: "Requested Links",
          icon: "mdi-movie-play-outline",
        },
        {
          to: "/all-users",
          title: "Users",
          icon: "mdi-account-group-outline",
        },
        {
          to: "/partners",
          title: "Partners",
          icon: "mdi-account-switch-outline",
        },
        {
          to: "/plans",
          title: "Plans",
          icon: "mdi-cube-outline",
        },
      ],
      profileLinks: [
        {
          to: "/account-settings",
          title: "Account Settings",
          icon: "mdi-cog-outline",
        },
        {
          to: "/payment-history",
          title: "Payment History",
          icon: "mdi-clipboard-list-outline",
        },
      ],
      drawer: !!this.$vuetify.breakpoint.mdAndUp,
      searchRecordings: "",
      timerId: null,
      pagesWithSearch: [
        "Received Recordings",
        "My Recordings",
        "Pending Requests",
        "Payments",
      ],
    };
  },
  computed: {
    ...mapGetters("auth", ["user", "loading"]),
    pageName() {
      let route = this.$route.name;
      let pageName = "";
      let excludedRoutes = [
        "RecordScreen",
        "MyRecordingDetails",
        "NewRequest",
        "SendScreenRecording",
        "AccountUpgrade",
        "PaymentMethod",
      ];
      if (!excludedRoutes.includes(route)) {
        pageName = this.$route.meta.title;
      }

      return pageName;
    },
  },
  methods: {
    ...mapMutations("auth", ["LOGOUT", "LOADING"]),
    async logout() {
      this.LOADING(true);
      try {
        await this.$axios.post("/user/logout");
        await this.LOGOUT();
        this.LOADING(false);
        await this.$router.push({ name: "Login" });
      } catch (e) {
        this.LOADING(false);
        this.$response.sendError(e);
      }
    },
    searchRequest() {
      if (this.timerId) {
        clearTimeout(this.timerId);
        this.timerId = null;
      }
      this.timerId = setTimeout(() => {
        this.$root.$emit("search", this.searchRecordings);
      }, 800);
    },
  },
  beforeDestroy() {
    clearTimeout(this.timerId);
  },
};
</script>

<style scoped>
.layout-bg {
  background: #eff3ff;
}
</style>
